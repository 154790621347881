<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">The first six ionization energies of an atom are:</p>

      <v-simple-table class="mb-4 ml-4" style="max-width: 169px">
        <thead>
          <tr>
            <td class="text-bold">Ionization</td>
            <td class="text-bold">Energy (kJ/mol)</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="field in fields" :key="field.ionization">
            <td>
              {{ field.ionization }}
            </td>
            <td>
              {{ field.energy }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-n3">a) To which main group family does this atom belong?</p>

      <v-radio-group
        v-model="inputs.multipleChoiceAnswer"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2 mt-2">b) I think this because:</p>

      <p class="mb-n6">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'CalPolyPracticeExam3Q2',
  components: {
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {text: 'Alkali metals', value: 'a0'},
        {text: 'Alkaline metals', value: 'a'},
        {text: "Boron's group", value: 'b'},
        {text: "Carbon's group", value: 'c'},
        {text: "Nitrogen's group", value: 'd'},
        {text: "Oxygen's group", value: 'e'},
      ],
      fields: [
        {ionization: '1st', energy: '786'},
        {ionization: '2nd', energy: '1580'},
        {ionization: '3rd', energy: '3230'},
        {ionization: '4th', energy: '4360'},
        {ionization: '5th', energy: '16,000'},
        {ionization: '6th', energy: '20,000'},
      ],
    };
  },
};
</script>
